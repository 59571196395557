import { CLIENT_REQUEST_HEADERS, HttpHeaderApp, HttpHeaderDevice, Maybe, serializeObjectForHttpHeader } from '@srgssr/rio-shared';

import { envDevelopment } from '../env/development.js';
import { envProduction } from '../env/production.js';
import envVarGenerated from '../generated/envExtra.json' assert { type: 'json' };

interface EnvVarsInterface {
  env: string;
  apolloClientUrl: string;
  eventGatewayUrl: string;
  eventGatewaySubscriptionKey: string;
  webBaseUrl: string;
  apiVersion: string;
  remoteConfigUrl: string;
  geoApiUrl: string;
  staticBucketUrl: string;
  port?: number;
  extra: EnvVarExtra;
  hotjarId?: number;
  hotjarVersion?: number;
  npawAccountCode?: string;
}

interface EnvVarExtra {
  buildId: string;
  appVersionName?: string;
  appBuildNumber?: string;
}

export const ENV_VARS = ((): EnvVarsInterface => {
  const envVars: EnvVarsInterface = {
    env: '',
    apolloClientUrl: '',
    eventGatewayUrl: '',
    eventGatewaySubscriptionKey: '',
    webBaseUrl: '',
    apiVersion: '',
    remoteConfigUrl: '',
    geoApiUrl: '',
    staticBucketUrl: '',
    extra: { buildId: '' },
    hotjarId: undefined,
    hotjarVersion: undefined,
    npawAccountCode: '',
  };
  let envVarFromConfig: any = null;
  switch (process.env.NODE_ENV) {
    case 'production': {
      envVarFromConfig = envProduction;
      break;
    }
    default: {
      envVarFromConfig = envDevelopment;
      break;
    }
  }
  // Read envVars from appropriate configFile
  if (envVarFromConfig) {
    envVars.env = envVarFromConfig.REACT_APP_ENV;
    envVars.apolloClientUrl = envVarFromConfig.REACT_APP_APOLLO_CLIENT_URL;
    envVars.eventGatewayUrl = envVarFromConfig.REACT_APP_EVENT_GATEWAY_URL;
    envVars.eventGatewaySubscriptionKey = envVarFromConfig.REACT_APP_EVENT_GATEWAY_SUBSCRIPTION_KEY;
    envVars.webBaseUrl = envVarFromConfig.REACT_APP_WEB_BASE_URL;
    envVars.apiVersion = envVarFromConfig.REACT_APP_API_VERSION;
    envVars.remoteConfigUrl = envVarFromConfig.REACT_APP_REMOTE_CONFIG_URL;
    envVars.geoApiUrl = envVarFromConfig.REACT_APP_GEO_API_URL;
    envVars.staticBucketUrl = envVarFromConfig.REACT_APP_STATIC_BUCKET_URL;
    envVars.hotjarId = envVarFromConfig.REACT_APP_HOTJAR_SIDEID;
    envVars.hotjarVersion = envVarFromConfig.REACT_APP_HOTJAR_VERSION;
    envVars.npawAccountCode = envVarFromConfig.REACT_NPAW_ACCOUNT_CODE;
  }
  if (envVarGenerated) {
    envVars.extra = envVarGenerated;
  }

  // Read and overwrite enVars if found in Environment Variables.
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV !== undefined) {
    envVars.env = process.env.REACT_APP_ENV;
  }
  if (process.env.REACT_APP_APOLLO_CLIENT_URL && process.env.REACT_APP_APOLLO_CLIENT_URL !== undefined) {
    envVars.apolloClientUrl = process.env.REACT_APP_APOLLO_CLIENT_URL;
  }
  if (process.env.REACT_APP_EVENT_GATEWAY_URL && process.env.REACT_APP_EVENT_GATEWAY_URL !== undefined) {
    envVars.eventGatewayUrl = process.env.REACT_APP_EVENT_GATEWAY_URL;
  }
  if (process.env.REACT_APP_EVENT_GATEWAY_SUBSCRIPTION_KEY && process.env.REACT_APP_EVENT_GATEWAY_SUBSCRIPTION_KEY !== undefined) {
    envVars.eventGatewaySubscriptionKey = process.env.REACT_APP_EVENT_GATEWAY_SUBSCRIPTION_KEY;
  }
  if (process.env.REACT_APP_WEB_BASE_URL && process.env.REACT_APP_WEB_BASE_URL !== undefined) {
    envVars.webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;
  }
  if (process.env.REACT_APP_API_VERSION && process.env.REACT_APP_API_VERSION !== undefined) {
    envVars.apiVersion = process.env.REACT_APP_API_VERSION;
  }
  if (process.env.REACT_APP_REMOTE_CONFIG_URL && process.env.REACT_APP_REMOTE_CONFIG_URL !== undefined) {
    envVars.remoteConfigUrl = process.env.REACT_APP_REMOTE_CONFIG_URL;
  }
  if (process.env.REACT_APP_GEO_API_URL && process.env.REACT_APP_GEO_API_URL !== undefined) {
    envVars.geoApiUrl = process.env.REACT_APP_GEO_API_URL;
  }
  if (process.env.REACT_APP_STATIC_BUCKET_URL && process.env.REACT_APP_STATIC_BUCKET_URL !== undefined) {
    envVars.staticBucketUrl = process.env.REACT_APP_STATIC_BUCKET_URL;
  }
  if (process.env.REACT_APP_HOTJAR_SIDEID && process.env.REACT_APP_HOTJAR_SIDEID !== undefined) {
    envVars.hotjarId = parseInt(process.env.REACT_APP_HOTJAR_SIDEID);
  }
  if (process.env.REACT_APP_HOTJAR_VERSION && process.env.REACT_APP_HOTJAR_VERSION !== undefined) {
    envVars.hotjarVersion = parseInt(process.env.REACT_APP_HOTJAR_VERSION);
  }
  if (process.env.REACT_NPAW_ACCOUNT_CODE && process.env.REACT_NPAW_ACCOUNT_CODE !== undefined) {
    envVars.npawAccountCode = process.env.REACT_NPAW_ACCOUNT_CODE;
  }

  return envVars;
})();

export const isProductionEnvironment = () => process.env.NODE_ENV === 'production';

export const isMajorVersionUpdate = (oldVersion: string, newVersion: string) =>
  parseInt(oldVersion.split('.')[0]) < parseInt(newVersion.split('.')[0]);

export const getVersionString = () => `${ENV_VARS.extra.appVersionName} (${ENV_VARS.extra.appBuildNumber})`;
export const getNpawVersionString = () => `${ENV_VARS.extra.appVersionName}_${ENV_VARS.extra.appBuildNumber}`;

export const createHeaders = ({
  others,
  platform,
  device,
  idToken,
  profileId,
  locale,
}: {
  others: Record<string, Maybe<string>>;
  platform: string;
  device: HttpHeaderDevice | undefined;
  idToken: Maybe<string>;
  profileId: Maybe<string>;
  locale: string;
}) => {
  const headers: typeof others & {
    [CLIENT_REQUEST_HEADERS.app.v2]?: string;
    [CLIENT_REQUEST_HEADERS.device.v2]?: string;
    [CLIENT_REQUEST_HEADERS.locale.v2]?: string;
    [CLIENT_REQUEST_HEADERS.profileId]?: string;
  } = {
    ...others,
    'x-playsuisse-locale': locale,
  };

  const app: HttpHeaderApp = {
    id: platform,
    version: ENV_VARS.extra.appVersionName,
  };
  if (platform !== 'web') {
    app.build = ENV_VARS.extra.appBuildNumber;
  }
  headers['x-playsuisse-app'] = serializeObjectForHttpHeader(app);

  if (device) headers['x-playsuisse-device'] = serializeObjectForHttpHeader(device);

  if (idToken) {
    headers.authorization = `Bearer ${idToken}`;
    if (profileId) headers['x-playsuisse-user-profile-id'] = profileId;
  }

  return headers;
};
