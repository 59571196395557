import { useContext as useReactContext, Context } from 'react';

import { NoUndefined } from '@srgssr/rio-shared';

export function useSafeContext<T>(context: Context<T>, name: string) {
  const ctx = useReactContext(context);
  if (ctx === undefined) {
    throw new Error(`must be inside a ${name} provider.`);
  }
  return ctx as NoUndefined<typeof ctx>;
}
