export const envProduction = {
  REACT_APP_ENV: 'production',
  REACT_APP_API_VERSION: '2.0.0',
  REACT_APP_APOLLO_CLIENT_URL: 'https://www.playsuisse.ch/api/graphql',
  REACT_APP_EVENT_GATEWAY_URL: 'https://data.playsuisse-datalab.com/gateway/events',
  REACT_APP_EVENT_GATEWAY_SUBSCRIPTION_KEY: 'c2db00036f3e4e02a161cd269b39a332',
  REACT_APP_WEB_BASE_URL: 'www.playsuisse.ch',
  REACT_APP_HOTJAR_SIDEID: 1779564,
  REACT_APP_HOTJAR_VERSION: 6,
  REACT_APP_REMOTE_CONFIG_URL: 'https://www.playsuisse.ch/api/remote-config',
  REACT_APP_GEO_API_URL: 'https://geolocation.akamaized.net',
  REACT_APP_STATIC_BUCKET_URL: 'https://cdn.playsuisse.ch',
  REACT_NPAW_ACCOUNT_CODE: 'playsuisse',
};
